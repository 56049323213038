import aboutImage from "../../Assets/Images/about.png";
import webIcon from "../../Assets/Icons/web-link.png";
import networkIcon from "../../Assets/Icons/network.png";
const About = () => {
  return (
    <div>
      <section className="dark:bg-gray-800 dark:text-gray-100 ">
        <div className="container flex flex-col justify-center p-6 mx-auto  lg:py-5 lg:flex-row lg:justify-between">
          <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
            <img
              src={aboutImage}
              alt=""
              className="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128"
            />
          </div>
          <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-primary uppercase rounded-full bg-teal-accent-400">
                About Us Genopi
              </p>
            </div>
            <h1 className="text-5xl font-bold leading-none text-white sm:text-3xl">
              Not Just Traditional Cyber & Immigration Firm
            </h1>
            <p className="mt-6 mb-8 text-sm sm:mb-12 text-gray-400">
              There are many variations of passages of Lorem Ipsum available but
              the majority have suffered alteration in some form, by injecte
              humour or randomised words which
            </p>
            <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
              <div className=" flex  items-center">
                <div className="icon_div">
                  <img src={webIcon} className="h-10 w-10 " alt="" />
                </div>
                <div className="ml-4">
                  <p className="text-white text-lg font-semibold flex-wrap">
                    Website <br /> Development
                  </p>
                </div>
              </div>
              <div className=" flex  items-center ">
                <div className="icon_div">
                  <img src={networkIcon} className="h-9 w-9 " alt="" />
                </div>
                <div className="ml-4">
                  <p className="text-white text-lg font-semibold flex-wrap">
                    Internal <br /> Networking
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl  lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10  sm:text-start lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-primary uppercase rounded-full bg-teal-accent-400">
              What We’re Offering
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl">
            All Professional IT Services
          </h2>
        </div>
        <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col justify-between p-5  rounded shadow-sm bg-gradient-to-r from-[#5131EF] to-[#4E90ED]">
            <div>
              <div className="flex items-center justify-center shadow-lg w-16 h-16 mb-4 rounded-full ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                  />
                </svg>
              </div>
              <h6 className="mb-2 font-bold leading-5 text-2xl text-white mt-4">
                Top Flexibility
              </h6>
              <p className="mb-3 text-sm text-white">
                A flower in my garden, a mystery in my panties. Heart attack
                never stopped old Big Bear.
              </p>
            </div>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-white"
            >
              Learn more
            </a>
          </div>
          <div className="flex flex-col justify-between p-5  rounded shadow-sm bg-gradient-to-r from-[#03051C] to-[#090B34] ">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gradient-to-r from-[#5131EF] to-[#4E90ED]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                  />
                </svg>
              </div>
              <h6 className="mb-2 font-bold leading-5 text-2xl text-white mt-4">
                Time Saving
              </h6>
              <p className="mb-3 text-sm text-white">
                Rough pomfret lemon shark plownose chimaera southern sandfish
                kokanee northern sea.
              </p>
            </div>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-white"
            >
              Learn more
            </a>
          </div>
          <div className="flex flex-col justify-between p-5 rounded shadow-sm bg-gradient-to-r from-[#03051C] to-[#090B34]">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gradient-to-r from-[#5131EF] to-[#4E90ED]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 17.25v-.228a4.5 4.5 0 00-.12-1.03l-2.268-9.64a3.375 3.375 0 00-3.285-2.602H7.923a3.375 3.375 0 00-3.285 2.602l-2.268 9.64a4.5 4.5 0 00-.12 1.03v.228m19.5 0a3 3 0 01-3 3H5.25a3 3 0 01-3-3m19.5 0a3 3 0 00-3-3H5.25a3 3 0 00-3 3m16.5 0h.008v.008h-.008v-.008zm-3 0h.008v.008h-.008v-.008z"
                  />
                </svg>
              </div>
              <h6 className="mb-2 font-bold leading-5 text-2xl text-white mt-4">
                Management
              </h6>
              <p className="mb-3 text-sm text-white">
                A slice of heaven. O for awesome, this chocka full cuzzie is as
                rip-off as a cracker.
              </p>
            </div>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-white"
            >
              Learn more
            </a>
          </div>
          <div className="flex flex-col justify-between p-5  rounded shadow-sm bg-gradient-to-r from-[#03051C] to-[#090B34]">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gradient-to-r from-[#5131EF] to-[#4E90ED]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
                  />
                </svg>
              </div>
              <h6 className="mb-2 font-bold leading-5 text-2xl text-white mt-4">
                Collaborative
              </h6>
              <p className="mb-3 text-sm text-white">
                Disrupt inspire and think tank, social entrepreneur but
                preliminary thinking think tank compelling.
              </p>
            </div>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-white"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
