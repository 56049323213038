import React from "react";
import webDev from "../../Assets/Images/software-development-web-coding-laptop-programming-concept-website-design-coding-remote-work-home_3482-5615.webp";
import appDev from "../../Assets/Images/13491207_Kerfin7-NEA-2220.jpg";
import uiDev from "../../Assets/Images/gradient-ui-ux-background_23-2149052117.webp";
const Projects = () => {
  return (
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-6 sm:mx-auto sm:text-center md:mb-10 lg:max-w-2xl">
          <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-primary uppercase rounded-full bg-teal-accent-400">
            Our Project
          </p>
          <h1 className="text-5xl font-bold leading-none text-white sm:text-3xl">
            Proud Team of Great Project
          </h1>
        </div>
        <div className="grid gap-6 row-gap-5 lg:grid-cols-3">
          <div>
            <img
              className="object-cover w-full h-64 mb-6 rounded shadow-lg lg:h-80 xl:h-96"
              src={webDev}
              alt=""
            />
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl text-white">
              Web Development
            </h5>
          </div>
          <div>
            <img
              className="object-cover w-full h-64 mb-6 rounded shadow-lg lg:h-80 xl:h-96"
              src={appDev}
              alt=""
            />
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl text-white">
              App Development
            </h5>
          </div>
          <div>
            <img
              className="object-cover w-full h-64 mb-6 rounded shadow-lg lg:h-80 xl:h-96"
              src={uiDev}
              alt=""
            />
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl text-white">
              Ui Design
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
