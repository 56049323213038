import brandIcon from "../../Assets/Images/Illustration.png";
export const Header = () => {
  return (
    <div className="relative flex items-center flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl ">
        <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
          <div className="max-w-xl mb-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-primary uppercase rounded-full bg-teal-accent-400">
                We Provide The Best Services
              </p>
            </div>
            <h2 className="max-w-lg  font-sans text-7xl font-bold tracking-tight text-white  sm:leading-none">
              We Bring Great Ideas to Life
            </h2>
          </div>
        </div>
      </div>
      <div className="inset-y-0 right-0 top-10  w-full max-w-xl px-4 mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-[40%] lg:max-w-full lg:absolute xl:px-0">
        <img
          className="object-cover w-full h-auto rounded  lg:h-auto"
          src={brandIcon}
          alt=""
        />
      </div>
    </div>
  );
};
