import MainLayout from "./Layout/MainLayout";

const App = () => {
  return (
    <div className="bg-gradient-to-r from-[#03051C] to-[#090B34]">
      <MainLayout />
    </div>
  );
};

export default App;
