import verifyImage from "../../Assets/Icons/verify.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Testimonial = () => {
  return (
    <div>
      <section className="dark:bg-gray-800 dark:text-gray-100 ">
        <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
          <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-primary uppercase rounded-full bg-teal-accent-400">
                Why Choose Us
              </p>
              <h3 className="text-2xl font-bold tracking-tight sm:text-3xl text-white">
                Examples Of Information Technology
              </h3>

              <div className="main_div grid grid-cols-12 mt-5 gap-6">
                <div className="bg-gradient-to-r from-[#5131EF] to-[#4E90ED] py-3 px-2 col-span-12 lg:col-span-4 rounded-full">
                  <p className="text-white text-center font-semibold">
                    Manufacturing
                  </p>
                </div>
                <div className="bg-[#090B34] py-3 px-2 col-span-12 lg:col-span-4 rounded-full">
                  <p className="text-white text-center font-semibold">
                    Manufacturing
                  </p>
                </div>
                <div className="bg-[#090B34] py-3 px-2 col-span-12 lg:col-span-4 rounded-full">
                  <p className="text-white text-center font-semibold">
                    Manufacturing
                  </p>
                </div>
              </div>

              <p className="mt-5 text-lg text-white">
                Ne dicta praesent ocurreret has, diam theophrastus at pro. Eos
                etiam regione ut, persius eripuit quo id. Sit te euismod
                tacimates.
              </p>
              <div className="mt-12 space-y-12">
                <div className="grid grid-cols-12">
                  <div className="flex col-span-6">
                    <div className="flex-shrink-0">
                      <span className="mr-1">
                        <img src={verifyImage} className="h-5 w-5" alt="" />
                      </span>
                    </div>
                    <div className="ml-2">
                      <h4 className="text-lg font-medium leading-6 text-white">
                        Focus on The Basics
                      </h4>
                    </div>
                  </div>
                  <div className="flex col-span-6">
                    <div className="flex-shrink-0">
                      <span className="mr-1">
                        <img src={verifyImage} className="h-5 w-5" alt="" />
                      </span>
                    </div>
                    <div className="ml-2">
                      <h4 className="text-lg font-medium leading-6 text-white">
                        Focus on The Basics
                      </h4>
                    </div>
                  </div>
                  <div className="flex col-span-6">
                    <div className="flex-shrink-0">
                      <span className="mr-1">
                        <img src={verifyImage} className="h-5 w-5" alt="" />
                      </span>
                    </div>
                    <div className="ml-2">
                      <h4 className="text-lg font-medium leading-6 text-white">
                        Focus on The Basics
                      </h4>
                    </div>
                  </div>
                  <div className="flex col-span-6">
                    <div className="flex-shrink-0">
                      <span className="mr-1">
                        <img src={verifyImage} className="h-5 w-5" alt="" />
                      </span>
                    </div>
                    <div className="ml-2">
                      <h4 className="text-lg font-medium leading-6 text-white">
                        Focus on The Basics
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="mt-10 lg:mt-0">
              <img
                src="https://tf.quomodosoft.com/antech/wp-content/uploads/2022/07/section-background-ilus2.svg"
                alt=""
                className="mx-auto rounded-lg shadow-lg "
              />
            </div>
          </div>
          <div className="">
            <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
              <div className="lg:col-start-2">
                <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-primary uppercase rounded-full bg-teal-accent-400">
                  Why Choose Us
                </p>
                <h3 className="text-2xl font-bold tracking-tight sm:text-3xl text-white">
                  Examples Of Information Technology
                </h3>
                <div className="mt-12 space-y-12 hidden lg:block">
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showArrows={false}
                    showStatus={false}
                    interval={5000}
                  >
                    <div>
                      <div className="h-full p-8 rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="block w-10 h-10 mb-5 text-[#5131EF]"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                        <p className="leading-relaxed mb-6 text-white">
                          Synth chartreuse iPhone lomo cray raw denim brunch
                          everyday carry neutra before they sold out fixie 90's
                          microdosing. Tacos pinterest fanny pack venmo,
                          post-ironic heirloom try-hard pabst authentic iceland.
                        </p>
                        {/* <div className="inline-flex items-center">
                          <img
                            alt="testimonial"
                            src="https://i.ibb.co/bd90CTC/rsz-profile-pic-1.png"
                            className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                          />
                          <span className="flex-grow flex flex-col pl-4">
                            <span className="title-font text-lg font-medium text-white">
                              Cristain
                            </span>
                            <span className="text-gray-500 text-sm">
                              UI DEVELOPER
                            </span>
                          </span>
                        </div> */}
                      </div>
                    </div>
                    <div>
                      <div className="h-full p-8 rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="block w-10 h-10 mb-5 text-[#5131EF]"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                        <p className="leading-relaxed mb-6 text-white">
                          Synth chartreuse iPhone lomo cray raw denim brunch
                          everyday carry neutra before they sold out fixie 90's
                          microdosing. Tacos pinterest fanny pack venmo,
                          post-ironic heirloom try-hard pabst authentic iceland.
                        </p>
                        {/* <div className="inline-flex items-center">
                          <img
                            alt="testimonial"
                            src="https://i.ibb.co/bd90CTC/rsz-profile-pic-1.png"
                            className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                          />
                          <span className="flex-grow flex flex-col pl-4">
                            <span className="title-font text-lg font-medium text-white">
                              Cristain
                            </span>
                            <span className="text-gray-500 text-sm">
                              UI DEVELOPER
                            </span>
                          </span>
                        </div> */}
                      </div>
                    </div>
                    <div>
                      <div className="h-full p-8 rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="block w-10 h-10 mb-5 text-[#5131EF]"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                        <p className="leading-relaxed mb-6 text-white">
                          Synth chartreuse iPhone lomo cray raw denim brunch
                          everyday carry neutra before they sold out fixie 90's
                          microdosing. Tacos pinterest fanny pack venmo,
                          post-ironic heirloom try-hard pabst authentic iceland.
                        </p>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
              <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
                <img
                  src="https://tf.quomodosoft.com/antech/wp-content/uploads/2022/07/sectioni-ilus.png"
                  alt=""
                  className="mx-auto rounded-lg shadow-lg dark:bg-gray-500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
