import About from "../Components/About/About";
import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";
import { Header } from "../Components/Header/Header";
import Navbar from "../Components/Navbar/Navbar";
import Projects from "../Components/Projects/Projects";
import Team from "../Components/Team/Team";
import Testimonial from "../Components/Testimonial/Testimonial";

const MainLayout = () => {
  return (
    <div className="w-[95%] mx-auto">
      <Navbar />
      <div className="w-[90%] mx-auto">
        <Header />
        <About />
        <Projects />
        <Testimonial />
        {/* <Team /> */}
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
